require("dropzone")
import Dropzone from 'dropzone'

var myDropzone = {}

document.addEventListener("turbolinks:load", function() {
    dropzoneSetting();
});

async function dropzoneSetting() {
    Dropzone.autoDiscover = false;
    //最大ファイルアップロードサイズ
    var maxFileSize = 10;
    if(document.getElementById('js-my-awesome-dropzone') != null){
        myDropzone = new Dropzone('#js-my-awesome-dropzone', {
            maxFilesize: maxFileSize,
            dictFileTooBig: 'ファイルサイズは' + maxFileSize + 'MBまで',
            acceptedFiles: 'image/jpeg,image/png,image/gif,image/bmp',
            previewsContainer: false,
            parallelUploads: 1,
            addedfile: function() {
                document.getElementsByClassName('js-dropzone-info').item(0).innerHTML = 'アップロード中…';
            },
            queuecomplete: function() {
                location.reload();
            }
        });
    }
}