// select2
import Select2 from "select2"

Select2()
$.fn.select2.defaults.set("allowClear", true)
$.fn.select2.defaults.set("placeholder", "")
$.fn.select2.defaults.set("width", "100%")
$.fn.select2.defaults.set("theme", "bootstrap4")

function search_params_conunt(){
    let count = 0;
    count += $('.js_plant_search input[type="checkbox"]:checked').length;
    $('.js_plant_search input[type="text"]').each(function(i,o){
        if($(o).val()){
            count++;
        }
    });
    $('.js_search_params_conunt').html(count);
    if(count > 0){
        $('.js_search_params_conunt_comment').show()
        $('.js_search_button').html('この条件で検索');
    }else{
        $('.js_search_params_conunt_comment').hide()
        $('.js_search_button').html('すべての植物を表示');
    }
}

$(document).on('turbolinks:load', function() {
    $('body').on('change', '.js_collect_plant_checked, .js_collect_plant_count', function(){
        let $form = $(this).closest('form');
        $form.find('.js_collect_plant_submit').click();
        $('.js_collect_plant_checked').prop({'disabled':true});
    });

    $('.js_plant_search input').change(search_params_conunt);
    search_params_conunt();

    $('.js_used_select2').select2()

    $('#js_add_plant_group_modal').on('hide.bs.modal', function () {
        $("input[name='plant_group[name]']").val('');
        $('.js_error_message').remove();
    })

    $('.js_add_plant_group_modal_show').click(function(){
        if($(this).hasClass('js_add_plant_group_modal_show_all') && $(".selected_plnats [name='plant_ids[]']").length <= 0){
            alert('植物を選択してください。');
            return false;
        }
        $('#js_add_plant_group_modal').modal('show');
    });

    $('.js_plant_check_all').click(function(){
        $('.js_plant_check').each(function(){
            $(this).prop('checked', true);
        });
        update_selected_plnats();
        return false;
    });

    $('.js_plant_uncheck_all').click(function(){
        $('.js_plant_check').each(function(){
            $(this).prop('checked', false);
        });
        update_selected_plnats();
        return false;
    });

    $('.js_plant_check').change(function(){
        update_selected_plnats();
    });

    function update_selected_plnats() {
        let count = 0;
        $('.selected_plnats').html('');
        $('.js_plant_check').each(function(){
            if($(this).prop('checked')){
                count++;
                let $input = $('<input/>',{type:'hidden', name:'plant_ids[]', value:$(this).val()});
                $input.appendTo('.selected_plnats');
            }
        });
        $('.selected_plnats_count').html(count);
    }

    $('.js_print_type_dropdown-item').click(function(){
        $(this).closest('form').find("input[name='print_type']").val($(this).attr('data-print-type'));
        $(this).closest('form').find('.dropdown-toggle').text($(this).text());
    });
});

