$(document).on('turbolinks:load', function() {
    $('.js_print_type_yupo').click(function(){
        $('#js_print_type_yupo_radio').prop('checked', true);
        view_option();
        $('#js_print_type_yupo_radio').change();
    });

    $('.js_print_type_pop').click(function(){
        $('#js_print_type_pop_radio').prop('checked', true);
        view_option();
        $('#js_print_type_pop_radio').change();
    });

    $('.js_print_type').change(function(){
        view_option();
        $('.js_selected_print_type').html($(this).data('value'));
    });

    $('.js_plant_check_all').click(function(){
        $('.js_plant_check').each(function(){
            $(this).prop('checked', true);
        });
        update_selected_plnats();
        return false;
    });

    $('.js_plant_uncheck_all').click(function(){
        $('.js_plant_check').each(function(){
            $(this).prop('checked', false);
        });
        update_selected_plnats();
        return false;
    });

    $('.js_plant_check').change(function(){
        update_selected_plnats();
    });

    function view_option(){
        if($('input[name="print_type"]:checked').val() == 'print_8_plants'){
            $('.js_print_option').show();
        }else{
            $('.js_print_option').hide();
        }
    }
    view_option();

    function update_selected_plnats() {
        let count = 0;
        $('.js_plant_check').each(function(){
            if($(this).prop('checked')){
                count++;
                $('input[name="print_count_plant_ids['+$(this).val()+']"]').val(1);
                $('input[name="print_count_plant_ids['+$(this).val()+']"]').closest('.js_plant_group_yupo_item').show();
                $('input[name="print_pops['+$(this).val()+'][print_count]"]').val(1);
                $('input[name="print_pops['+$(this).val()+'][print_count]"]').closest('.js_plant_group_pop_item').show();
            }else{
                $('input[name="print_count_plant_ids['+$(this).val()+']"]').val(0);
                $('input[name="print_count_plant_ids['+$(this).val()+']"]').closest('.js_plant_group_yupo_item').hide();
                $('input[name="print_pops['+$(this).val()+'][print_count]"]').val(0);
                $('input[name="print_pops['+$(this).val()+'][print_count]"]').closest('.js_plant_group_pop_item').hide();
            }
        });
        $('.selected_plnats_count').html(count);
    }
    update_selected_plnats();

    $('.js_plant_group_edit_modal_show').click(function(){
        $('#js_plant_group_edit_modal').modal('show');
    });

    $('.js_print').click(function(){
        let message = '';
        let count = 0;
        $('.js_plant_check').each(function(){
            if($(this).prop('checked')){
                count++;
            }
        });
        if(count <= 0) {
            message = message + "植物を選択してください。\n";
        }
        if(!$('input[name=print_type]:checked').val()){
            message = message + "印刷方法を選択してください。\n";
        }

        if (message != ''){
            alert(message);
            return false;
        }
    });
});

