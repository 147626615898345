function notify(message, type) {
    $.notify({
        message
    },{
        element: 'body',
        position: null,
        type: type,
        allow_dismiss: true,
        newest_on_top: false,
        showProgressbar: false,
        placement: {
            from: "top",
            align: "center"
        },
        offset: 20,
        spacing: 10,
        z_index: 1031,
        delay: 5000,
        timer: 1000,
        width: 320,
        mouse_over: null,
        animate: {
            enter: 'animated fadeInDown',
            exit: 'animated fadeOutUp'
        },
        onShow: null,
        onShown: null,
        onClose: null,
        onClosed: null,
    });
}

document.addEventListener("turbolinks:load", function() {
    if($('.js_notice').length) { notify($('.js_notice').text(),"info") }
    if($('.js_alert').length)  { notify($('.js_alert').text(),"danger") }
});