require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("bootstrap4-notify")
import './javascripts/jquery-import';
import '@fortawesome/fontawesome-free/js/all';
import './javascripts/plants'
import './javascripts/dropzone'
import './javascripts/plant_groups'
import './javascripts/notify'
// import 'popper.js/dist/popper';